<template>
  <transition
  @before-enter="beforeSearchShow"
  enter-active-class="animate__animated animate__fadeIn animate__faster"
  leave-active-class="animate__animated animate__fadeOut animate__faster"
  @after-leave="searchHidden">
    <div v-show="showMobileSearch">
      <no-ssr>
        <overlay-scrollbars :options="{ overflowBehavior: {x: 'hidden', y: 'scroll'}, className: 'os-theme-minimal-dark' }" class="bcm-instant-search">
          <template v-if="showComponent">
            <ais-instant-search
              :search-client="searchClient"
              :index-name="getIndexName"
            >
              <div class="bcm-instant-search-initial">
                <!-- <div class="wrapp-close text-right mb-2">
                  <button class="btn btn-none" @click.prevent="closeSearch">
                    <i class="icomoon-icon icon-x cl-black" />
                  </button>
                </div> -->
                <!-- Manage Extra Info Color in Facets -->
                <ais-configure :hitsPerPage="100" clickAnalytics="true" style="display: none;" />
                <ais-hits :transform-items="manageColorFilter" style="display: none;" />

                <div class="search-box">
                  <!-- search box -->
                  <ais-search-box :class-names="getSearchBoxClassNames">
                    <template v-slot="{ currentRefinement, refine }">
                      <div class="bcm-search-box-form">
                        <input
                        type="search"
                        :value="currentRefinement"
                        autocorrect="off"
                        autocapitalize="off"
                        autocomplete="off"
                        spellcheck="false"
                        required="required"
                        maxlength="512"
                        aria-label="Search"
                        :placeholder="inputPlaceholder"
                        class="ais-SearchBox-input bcm-search-box-input"
                        @input="refineSearch(refine, $event.currentTarget.value)">
                        <!-- <button type="reset" title="Clear" class="ais-SearchBox-reset bcm-search-reset" @click.prevent="refineSearch(refine, '')" :class="{ 'fadeIn': searchBoxQuery }" ref="searchReset">
                          <i class="icomoon-icon icon-x"></i>
                        </button> -->
                      </div>
                      <i class="icomoon-icon icon-search nav-icon"></i>
                    </template>
                  </ais-search-box>

                  <!-- stats -->
                  <ais-stats :class-names="getStatsClassNames" :class="{ 'fadeIn' : !isFirstUse, 'fadeOut': isFirstUse }" v-show="!isFirstUse">
                    <template v-slot="{ nbHits }">
                      <span class="text">{{$t('algolia.stats.resultsFound')}} <span class="line" /><b class="hits-found">{{nbHits}}</b></span>
                    </template>
                  </ais-stats>

                  <!-- clear active filters (exclude: query search) -->
                  <ais-clear-refinements>
                    <div slot-scope="{ canRefine, refine, createURL }">
                      <a
                        :href="createURL()"
                        @click.prevent="clearAllRefinements(refine)"
                        v-if="canRefine"
                      >
                        {{$t('algolia.refinements.clearAllRefinements')}}
                      </a>
                    </div>
                  </ais-clear-refinements>

                  <!-- configure search nbHist per page -->
                  <ais-configure :hitsPerPage="4" />
                </div>

                <!-- CATEGORIES SUGGESTED LINK TO SHOW IN ALGOLIA  -->
                <div class="bcm-suggested-categories" v-show="isFirstUse && suggested.length" :class="{ 'fadeOut' : searchBoxQuery }">
                  <h3 class="title">{{$t('search.refinements.categoriesSuggested')}}</h3>
                  <ul>
                    <li v-for="(category, index) in suggested" :key="index">
                      <router-link
                        class="text-decoration-none"
                        :to="'listing' | localizedByNameCategories(category.category_url_path, null, $store, $router)"
                      >
                        <span class="link-label">{{ category.category_name }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <!-- CATEGORIES LINK TO SHOW IN ALGOLIA  -->
                <!-- v-show="getCategoriesFromMagento.length" -->
                <div class="bcm-suggested-categories" v-show="isFirstUse && categories.length" :class="{ 'fadeOut' : searchBoxQuery }">
                  <h3 class="title">{{$t('search.refinements.categories')}}</h3>
                  <ul>
                    <li v-for="(category, index) in categories" :key="index">
                      <router-link
                        class="text-decoration-none"
                        :to="'listing' | localizedByNameCategories(category.category_url_path, null, $store, $router)"
                      >
                        <span class="link-label">{{ category.category_name }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="bcm-instant-search-mobile" v-show="!isFirstUse && totalItems">
                <div class="bcm-instant-search-toggle">
                  <b-button v-b-toggle="'collapse-sort'" class="bcm-instant-search-toggle__button">{{$t('search.sort.sortBy')}}</b-button>
                  <b-button v-b-toggle="'collapse-filter'" class="bcm-instant-search-toggle__button">{{$t('search.refinements.filter')}}</b-button>
                </div>

                <!-- element to collapse for filter -->
                <b-collapse v-model="collapseFilter" accordion="main-accordions" id="collapse-filter">
                  <!-- microtype filter mobile -->
                  <div class="filter">
                    <div class="grid-and-collapse">
                      <button v-b-toggle="'accordion-filter-categories'" class="collapsed">
                        <span class="title">{{ $t('algolia.refinements.categories') }}</span>
                        <i class="icomoon-icon icon-chevron-down"></i>
                      </button>
                      <b-collapse accordion="generic-filters" id="accordion-filter-categories" class="collapse-item">
                        <!-- <no-ssr> -->
                          <ais-hierarchical-menu
                            :attributes="[
                              'categories.level0',
                              'categories.level1',
                              'categories.level2',
                              'categories.level3'
                            ]"
                            separator=" /// "
                          >
                            <div
                              slot-scope="{
                                items,
                                refine,
                                createURL
                              }"
                            >
                              <hierarchical-menu-list
                                :items="items"
                                :refine="refine"
                                :createURL="createURL"
                              />
                            </div>
                          </ais-hierarchical-menu>
                        <!-- </no-ssr> -->
                      </b-collapse>
                    </div>
                  </div>

                  <!-- GENDER ATTR -->
                  <div class="filter">
                    <ais-refinement-list operator="or" attribute="gender">
                      <template v-slot:default="{items, refine}">
                        <div class="grid-and-collapse">
                          <button v-b-toggle="'accordion-filter-gender'" class="collapsed">
                            <span class="title">{{ `${$t('Gender')} ${facetGenderSelected.length ? `(${facetGenderSelected.length})`: ''}` }}</span>
                            <i class="icomoon-icon icon-chevron-down"></i>
                          </button>
                          <b-collapse accordion="generic-filters" id="accordion-filter-gender" class="collapse-item">
                            <b-form-group v-slot="{ ariaDescribedby }" id="facet-gender">
                              <b-form-checkbox
                                name="checkbox-facet-gender"
                                v-for="item in items"
                                :key="item.value"
                                :class="'facet-gender-' + item.value"
                                v-model="facetGenderSelected"
                                :value="item.value"
                                @change="refine(item.value)"
                                class="ais-refinement-list--checkbox"
                                :aria-describedby="ariaDescribedby"
                              >
                                {{item.label}}
                              </b-form-checkbox>
                            </b-form-group>
                          </b-collapse>
                        </div>
                      </template>
                    </ais-refinement-list>
                  </div>

                  <!-- color filter mobile -->
                  <div class="filter">
                    <ais-refinement-list operator="and" attribute="color_filter.label">
                      <template v-slot:default="{items, refine}">
                        <div class="grid-and-collapse">
                          <button v-b-toggle="'accordion-filter-color'" class="collapsed">
                            <span class="title">{{ `${$t('color')} ${facetColorSelected.length ? `(${facetColorSelected.length})`: ''}` }}</span>
                            <i class="icomoon-icon icon-chevron-down"></i>
                          </button>
                          <b-collapse accordion="generic-filters" id="accordion-filter-color" class="collapse-item">
                            <!-- <no-ssr> -->
                              <div class="wrapp-collapse">
                                <div class="wrapp-collapse-block">
                                  <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-facet-color" class="checkbox_color_filter">
                                    <div v-for="item in colorItems(items)" :key="item.value">
                                      <b-form-checkbox
                                        name="checkbox-facet-color"
                                        v-if="isColorVisible(item)"
                                        v-model="facetColorSelected"
                                        class="ais-refinement-list--checkbox"
                                        :key="item.value"
                                        :value="item.value"
                                        :class="'facet-color-' + item.value"
                                        :aria-describedby="ariaDescribedby"
                                        @change="refine(item.value)"
                                      >
                                        <instant-search-swatch-color :item="item" :colors="colorFilter" />
                                      </b-form-checkbox>
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            <!-- </no-ssr> -->
                          </b-collapse>
                        </div>
                      </template>
                    </ais-refinement-list>
                  </div>

                  <!-- size filter mobile -->
                  <div class="filter">
                    <ais-refinement-list operator="and" attribute="size_filter">
                      <template v-slot:default="{items, refine}">
                        <div class="grid-and-collapse">
                          <button v-b-toggle="'accordion-filter-size'" class="collapsed">
                            <span class="title">{{ `${$t('size')} ${facetSizeSelected.length ? `(${facetSizeSelected.length})`: ''}` }}</span>
                            <i class="icomoon-icon icon-chevron-down"></i>
                          </button>
                          <b-collapse accordion="generic-filters" id="accordion-filter-size" class="collapse-item">
                            <!-- <no-ssr> -->
                              <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-facet-size">
                                <b-form-checkbox
                                  name="checkbox-facet-size"
                                  v-for="item in items"
                                  class="ais-refinement-list--checkbox"
                                  v-model="facetSizeSelected"
                                  :key="item.value"
                                  :class="`facet-size-${item.value}`"
                                  :value="item.value"
                                  :aria-describedby="ariaDescribedby"
                                  @change="refine(item.value)"
                                >
                                  {{item.label}}
                                </b-form-checkbox>
                              </b-form-group>
                            <!-- </no-ssr> -->
                          </b-collapse>
                        </div>
                      </template>
                    </ais-refinement-list>
                  </div>
                </b-collapse>
                <!-- element to collapse for sort by -->
                <b-collapse v-model="collapseSort" accordion="main-accordions" id="collapse-sort">
                  <!-- sort mobile -->
                  <div class="filter">
                    <div class="grid-and-collapse">
                      <div class="collapse show pt-4">
                        <ais-sort-by :items="getSortCriteria">
                          <template v-slot:default="{items, refine}">
                            <no-ssr>
                              <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-sort">
                                <b-form-checkbox
                                  name="checkbox-sort"
                                  v-for="item in items"
                                  v-model="sortCriteriaSelected"
                                  class="ais-refinement-list--checkbox"
                                  :key="item.value"
                                  :class="'sort-' + item.value"
                                  :disabled="sortCriteriaSelected.includes(item.value)"
                                  :value="item.value"
                                  :aria-describedby="ariaDescribedby"
                                  @change="refine(item.value)"
                                >
                                  {{$t('algolia.sort.' + item.label)}}
                                </b-form-checkbox>
                              </b-form-group>
                            </no-ssr>
                          </template>
                        </ais-sort-by>
                      </div>
                    </div>
                  </div>
                </b-collapse>

                <!-- clear active filters (exclude: query search) -->
                <ais-clear-refinements>
                  <div slot-scope="{ canRefine, refine }">
                    <b-button v-if="canRefine" @click.prevent="clearAllRefinements(refine)" class="bcm-instant-search-toggle__button--clear btn btn-gray">
                      {{$t('algolia.refinements.clearAllRefinements')}}
                    </b-button>
                    <b-button v-else-if="collapseFilter || collapseSort" @click.prevent="closeCollapse()" class="bcm-instant-search-toggle__button--clear btn btn-gray">
                      {{$t(canRefine ? 'algolia.refinements.clearAllRefinements' : 'Close')}}
                    </b-button>
                  </div>
                </ais-clear-refinements>
              </div>

              <!-- results -->
              <div v-show="!isFirstUse">
                <ais-infinite-hits
                :class-names="{
                  'ais-InfiniteHits': 'bcm-hits',
                  'ais-InfiniteHits-list': 'bcm-hits-list',
                  'ais-InfiniteHits-item': 'bcm-hits-item'
                  }"
                  :transform-items="manageItems">
                  <div class="bcm-instant-search-tile-container" slot="item" slot-scope="{ item }">
                    <instant-search-tile :item="item" :currentIndex="currentIndex"></instant-search-tile>
                  </div>
                  <template v-slot:loadMore="{ isLastPage, refineNext }">
                    <div class="pagination-load-more" v-show="!isLastPage">
                      <a class="bcm-link bcm-effect-hover" @click.prevent="refineNext">
                        {{ $t('Load more') }}
                      </a>
                    </div>
                  </template>
                </ais-infinite-hits>
              </div>

              <!-- state-results -->
              <!-- <ais-state-results class="bcm-state-results">
                <p
                  class="no-results-found"
                  slot-scope="{ state: { query }, results: { hits } }"
                  v-show="!hits.length"
                >
                  {{$t('algolia.stateResults.noResults')}} "{{ query }}"
                </p>
              </ais-state-results> -->

              <instant-search-quick-view v-show="quickViewInstantSearch"></instant-search-quick-view>
            </ais-instant-search>
          </template>
        </overlay-scrollbars>
      </no-ssr>
    </div>
  </transition>
</template>

<script>
import InstantSearchDesktop from 'theme/components/InstantSearch/InstantSearchDesktop.vue'

export default {
  name: 'InstantSearchMobile',
  mixins: [InstantSearchDesktop],
  data () {
    return {
      showMobileSearch: false,
      inputPlaceholder: this.$t('search.searchBox.title.desktop')
    }
  },
  methods: {
    clearPlaceholder () {
      this.inputPlaceholder = ''
    }
  },
  mounted () {
    this.showMobileSearch = true
    $('.leftbar-overlay').hide()
    if (process.client) {
      $('.ais-SearchBox-input.bcm-search-box-input').focus()
    }
  },
  components: {
    InstantSearchDesktop
  }
}
</script>

<style>
  .slide-down-enter-active, .slide-down-leave-active {
    transition: all .6s cubic-bezier(.5,.1,0,.85) 0s;
  }
  .slide-down-enter-to, .slide-down-leave {
    opacity: 1;
    max-height: 1000px;
    overflow: hidden;
  }
  .slide-down-enter, .slide-down-leave-to {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
</style>
